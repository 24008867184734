var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "mb-3"
  }, [_vm._v("Create/edit kiosk devices")]), _c('div', [_c('div', {
    staticClass: "row justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('FormGroup', {
    attrs: {
      "id": "name",
      "label": "Name",
      "error": _vm.validationErrors.name,
      "description": "A name for your own reference"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "rounded-md bg-lighter p-2 mb-3"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.enabled,
      expression: "form.enabled"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "enabled",
      "type": "checkbox",
      "name": "enabled"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.enabled) ? _vm._i(_vm.form.enabled, null) > -1 : _vm.form.enabled
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.form.enabled,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "enabled", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "enabled", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "enabled", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "enabled"
    }
  }, [_vm._v("Enabled")])])])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('FormGroup', {
    attrs: {
      "id": "type",
      "type": "select",
      "options": _vm.typeOptions,
      "label": "Type",
      "description": "Kiosk type",
      "error": _vm.validationErrors.type
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1), _c('div', {
    staticClass: "mb-3"
  }, [_vm.assetOptions.length > 0 && _vm.form.type === 'asset' ? _c('FormGroup', {
    attrs: {
      "id": "assetId",
      "type": "select",
      "config": {
        allowSearch: true,
        allowClear: true
      },
      "options": _vm.assetOptions,
      "label": "Asset",
      "description": "Select the asset this kiosk is for",
      "placeholder": "Select an asset",
      "error": _vm.validationErrors.assetId
    },
    on: {
      "input": _vm.onSelectAsset
    },
    model: {
      value: _vm.form.assetId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "assetId", $$v);
      },
      expression: "form.assetId"
    }
  }) : _c('Spinner')], 1), _c('div', {
    staticClass: "mb-3"
  }, [_c('FormGroup', {
    attrs: {
      "id": "slides",
      "type": "multi-check-options",
      "options": _vm.slideOptions,
      "label": "Slides",
      "description": "Select the slides to display on this kiosk",
      "error": _vm.validationErrors.slides
    },
    model: {
      value: _vm.form.slides,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "slides", $$v);
      },
      expression: "form.slides"
    }
  })], 1), _c('div', {
    staticClass: "mb-3"
  }, [_c('FormGroup', {
    attrs: {
      "id": "slideInterval",
      "type": "number",
      "label": "Slide Interval (ms)",
      "description": "How long each slide should show for in milliseconds",
      "error": _vm.validationErrors.slideInterval
    },
    model: {
      value: _vm.form.slideInterval,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "slideInterval", $$v);
      },
      expression: "form.slideInterval"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }