<template>
  <div>
    <p class="mb-3">Create/edit kiosk devices</p>
    <div>
      <div class="row justify-content-between align-items-center">
        <div class="col-md-8">
          <FormGroup id="name" v-model="form.name" label="Name" :error="validationErrors.name" description="A name for your own reference" />
        </div>
        <div class="col-md-4">
          <div class="rounded-md bg-lighter p-2 mb-3">
            <div class="custom-control custom-checkbox">
              <input id="enabled" v-model="form.enabled" type="checkbox" class="custom-control-input" name="enabled" />
              <label class="custom-control-label" for="enabled">Enabled</label>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <FormGroup
          id="type"
          v-model="form.type"
          type="select"
          :options="typeOptions"
          label="Type"
          description="Kiosk type"
          :error="validationErrors.type"
        />
      </div>
      <div class="mb-3">
        <FormGroup
          v-if="assetOptions.length > 0 && form.type === 'asset'"
          id="assetId"
          v-model="form.assetId"
          type="select"
          :config="{ allowSearch: true, allowClear: true }"
          :options="assetOptions"
          label="Asset"
          description="Select the asset this kiosk is for"
          placeholder="Select an asset"
          :error="validationErrors.assetId"
          @input="onSelectAsset"
        />
        <Spinner v-else />
      </div>
      <div class="mb-3">
        <FormGroup
          id="slides"
          v-model="form.slides"
          type="multi-check-options"
          :options="slideOptions"
          label="Slides"
          description="Select the slides to display on this kiosk"
          :error="validationErrors.slides"
        />
      </div>
      <div class="mb-3">
        <FormGroup
          id="slideInterval"
          v-model="form.slideInterval"
          type="number"
          label="Slide Interval (ms)"
          description="How long each slide should show for in milliseconds"
          :error="validationErrors.slideInterval"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';
import Spinner from '@/components/Spinner';
import { capitalize } from '@/lib/helpers';

export default {
  components: {
    FormGroup,
    Spinner
  },
  extends: Form,
  data() {
    return {
      assets: [],
      assetOptions: [],
      optisignsScreenOptions: [],
      slideOptions: [
        { value: 'Energy', label: 'Energy' },
        { value: 'Carbon', label: 'Carbon' },
        { value: 'EnergyLeaderboard', label: 'Energy Leaderboard' },
        { value: 'HHGraph', label: 'Half Hour Graph' },
        { value: 'Benchmark', label: 'Benchmark' },
        { value: 'LocalNews', label: 'Local News' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      validationErrors: 'kiosk/validationErrors',
      schema: 'kiosk/schema'
    }),
    typeOptions() {
      return this.schema.find(s => s.value === 'type').enum.map(value => ({ value, label: capitalize(value) }));
    }
  },
  async beforeMount() {
    this.assets = await this.$store.dispatch('asset/list', { data: { params: { $limit: 10000, $select: 'siteName,entityId' } } });
    this.assetOptions = this.assets.data.map(asset => ({ value: asset._id, label: asset.siteName }));
    this.assetOptions.sort((a, b) => a.label.localeCompare(b.label));

    const optisignsScreen = await this.$store.dispatch('kiosk/getOptisignsScreens');
    this.optisignsScreenOptions = optisignsScreen.map(screen => ({ value: screen._id, label: screen.deviceName }));
  },
  async mounted() {},
  methods: {
    onSelectAsset(value) {
      const asset = this.assets.data.find(asset => asset._id === value);
      this.form.entityId = asset.entityId;
    }
  }
};
</script>
